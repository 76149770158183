<template>
  <v-row>
    <v-col cols="12">
      <v-form @submit.prevent="submit">
        <v-text-field
          v-model="query"
          rounded
          solo
          clearable
          hide-details
          spellcheck="false"
        ></v-text-field>
      </v-form>
    </v-col>
    <v-col cols="12">
      <v-data-table :headers="headers" :items="results" multi-sort v-if="results.length > 0">
        <template v-slot:item.connected="{ item }">
          <v-badge avatar overlap dot :color="isConnected(item.connected)">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-avatar color="grey" size="36" v-bind="attrs" v-on="on">
                  <img :src="getClassImage(item.class)" />
                </v-avatar>
              </template>
              <span>{{ getClassName(item.class) }}</span>
            </v-tooltip>
          </v-badge>
        </template>
        <template v-slot:item.updated_at="{ item }">
          {{ parseUpdateTime(item.updated_at) }}
        </template>
        <template v-slot:item.guild="{ item }">
          <div class="guild-btn" @click="inlineQuery('guild:' + item.guild)">
            {{ item.guild }}
          </div>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.VUE_APP_SUPABASE_URL;
const supabaseKey = process.env.VUE_APP_SUPABASE_KEY;

const supabase = createClient(supabaseUrl, supabaseKey);

export default {
  name: "search",
  props: {},
  data() {
    return {
      query: "",
      headers: [
        { text: "", value: "connected" },
        { text: "Family", value: "family_name" },
        { text: "Character", value: "character_name" },
        { text: "Guild", value: "guild" },
        { text: "Channel", value: "channel" },
        { text: "Last Seen", value: "updated_at" },
      ],
      results: [],
    };
  },
  methods: {
    async submit() {
      let query  = this.query.split(":")
      let parsed = ""

      let qtype  = ""
      let qterm  = ""

      if (query.length > 1) {
        qtype = query[0]
        qterm = query[1].split(" or ")
      } else {
        qtype = "family_name"
        qterm = query[0].split(" or ")
      }

      for (let i = 0; i < qterm.length; i++) {
        if (i != 0)
          parsed += ","
        parsed += `${qtype}.ilike.${qterm[i]}`
      }

      let { data, error } = await supabase
        .from("players")
        .select()
        .or(parsed)
      
      if (error)
        return
      
      this.results = data
    },
    inlineQuery(query) {
      this.query = query;
      this.submit();
    },
    isConnected(b) {
      return b ? "green" : "grey";
    },
    getClassImage(c) {
      return `https://s1.pearlcdn.com/NAEU/contents/img/common/character/character${c}_sml.jpg`;
    },
    getClassName(c) {
      switch (c) {
        case 0:
          return "Warrior";
        case 4:
          return "Ranger";
        case 8:
          return "Sorceress";
        case 12:
          return "Berserker";
        case 16:
          return "Tamer";
        case 1:
          return "Hashashin";
        case 2:
          return "Sage";
        case 5:
          return "Guardian";
        case 9:
          return "Nova";
        case 10:
          return "Corsair";
        case 11:
          return "Lahn";
        case 17:
          return "Shai";
        case 19:
          return "Striker";
        case 20:
          return "Musa";
        case 21:
          return "Maehwa";
        case 23:
          return "Mystic";
        case 24:
          return "Valkyrie";
        case 25:
          return "Kunoichi";
        case 26:
          return "Ninja";
        case 27:
          return "Dark Knight";
        case 28:
          return "Wizard";
        case 29:
          return "Archer";
        case 31:
          return "Witch";
      }
    },
    parseUpdateTime(t) {
      const delta = Date.now() - Date.parse(t);

      const d = 1000 * 60 * 60 * 24; // Days
      const h = 1000 * 60 * 60; // Hours
      const m = 1000 * 60; // Minutes
      const s = 1000; // Seconds

      if (delta > d) return ((delta / d) | 0) + " d";

      if (delta > h) return ((delta / h) | 0) + " h";

      if (delta > m) return ((delta / m) | 0) + " m";

      if (delta > s) return ((delta / s) | 0) + " s";

      return "Just now.";
    },
  },
  computed: {},
  created() {},
};
</script>

<style scoped>
tbody tr {
  height: 60px;
}
.guild-btn {
  cursor: pointer;
}
</style>
<template>
  <v-app>
    <v-system-bar>
      <v-spacer></v-spacer>
      <v-icon color="red">mdi-antenna</v-icon>
    </v-system-bar>
    <v-main>
      <v-container fill-height>
        <search />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import search from "./components/search";

export default {
  name: "App",

  components: {
    search,
  },

  data: () => ({
    //
  }),
};
</script>

<style>
html {
  overflow: auto;
}
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-background-clip: text!important;
}
/*Change text in autofill textbox*/
input:-webkit-autofill
{
 -webkit-text-fill-color: white !important;
}
</style>